import React from "react";
import pt from "date-fns/locale/pt";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage, Field } from "formik";

import DatePicker, { registerLocale } from "react-datepicker";
import Input from "../../../../components/Input/Input";
import { InputCurrency } from "../../../../components/Input/InputCurrency";

registerLocale("pt", pt);

const CommercialDataForm = ({ values, setFieldValue, handleChange }) => {
    return (
        <div>
            <div className="overflow-auto h-70vh w-full pr-4">
                <label htmlFor="generalRestrictionsInformation" className="text-roxo_oficial font-bold mt-4">
                    Restrições gerais
                </label>
                <Input
                    name="commercial.generalRestrictionsInformation"
                    id="generalRestrictionsInformation"
                    onChange={handleChange}
                    placeholder="Preencha as restrições gerais"
                />
                <ErrorMessage
                    component="p"
                    name="commercial.generalRestrictionsInformation"
                    className="text-red text-xs font-light w-full"
                />
                <label htmlFor="profitabilityInformation" className="text-roxo_oficial font-bold mt-4">
                    Rentabilidade
                </label>
                <Input
                    name="commercial.profitabilityInformation"
                    id="profitabilityInformation"
                    onChange={handleChange}
                    placeholder="Preencha a rentabilidade"
                />
                <ErrorMessage
                    component="p"
                    name="commercial.profitabilityInformation"
                    className="text-red text-xs font-light w-full"
                />
                <label htmlFor="contractValidUntil" className="text-roxo_oficial font-bold mt-4">
                    Validade do contrato
                </label>
                <DatePicker
                    locale="pt"
                    selected={values?.commercial?.contractValidUntil && new Date(values.commercial.contractValidUntil)}
                    dateFormat="dd/MM/yyyy"
                    className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                    name="commercial.contractValidUntil"
                    onChange={value => setFieldValue("commercial.contractValidUntil", value)}
                />
                <ErrorMessage
                    component="p"
                    name="commercial.contractValidUntil"
                    className="text-red text-xs font-light w-full"
                />
                <label htmlFor="annualBilling" className="text-roxo_oficial font-bold mt-4">
                    Faturamento anual
                </label>
                <InputCurrency
                    name="commercial.annualBilling"
                    id="annualBilling"
                    value={values?.commercial?.annualBilling}
                    setFieldValue={setFieldValue}
                    placeholder="Preencha o faturamento anual"
                />
                <ErrorMessage component="p" name="commercial.annualBilling" className="text-red text-xs font-light w-full" />
                <label htmlFor="additionalInformation" className="flex text-roxo_oficial gap-1 font-bold mt-4">
                    Comentários adicionais <span className="text-C5 font-normal ">(opcional)</span>
                </label>
                <Field
                    as="textarea"
                    rows="4"
                    className="w-full rounded-md border py-1 px-2 border-C2"
                    name="commercial.additionalInformation"
                    id="additionalInformation"
                    onChange={handleChange}
                    value={values?.commercial?.additionalInformation}
                />
            </div>
        </div>
    );
};

export default CommercialDataForm;