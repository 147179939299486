import React from "react";
import NumberFormat from "react-number-format";

export const InputCurrency = ({ name, id, value, setFieldValue, placeholder }) => {
    return (
        <NumberFormat
            className="w-full rounded-md border py-1 px-2 border-C2 h-10"
            name={name}
            id={id}
            thousandsGroupStyle="thousand"
            thousandSeparator="."
            value={value}
            decimalSeparator=","
            prefix="R$ "
            displayType="input"
            type="text"
            decimalScale={2}
            fixedDecimalScale={true}
            onValueChange={values => {
                const { formattedValue } = values;
                setFieldValue(name, formattedValue);
            }}
            placeholder={placeholder}
        />
    );
};
